import { getToken, setToken, removeToken } from '@/utils/auth'
import {
  getUserInfo,
  logout,
  login,
  loginByMobile,
  loginByToken,
} from '@/api/user'

const state = {
  token: getToken(),
  id: 0,
  name: '', // 姓名
  mobile: '', // 手机
  dept: '', // 部门
  job: '', // 职务
  is_leader: false,
  is_note: false,
  approve_note: false,
  is_leave: false,
  approve_leave: false,
  data_board_note: false,
  data_board_today: false,
  data_board_leave_meeting: false,
  data_board_leave_tl: false,
  data_board_leave_other: false,
  // is_scrivener_dept: false,
  note_approve_list: [],
  meeting_approve_list: [],
  tl_approve_list: [],
  scrivener_list: [],
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERINFO: (state, info) => {
    state.id = info.id
    state.name = info.name
    state.mobile = info.mobile
    state.dept = info.dept
    state.job = info.job
    state.is_leader = info.is_leader === 1
    state.is_note = info.is_note === 1
    state.approve_note = info.approve_note === 1
    state.is_leave = info.is_leave === 1
    state.approve_leave = info.approve_leave === 1
    state.data_board_note = info.data_board_note === 1
    state.data_board_today = info.data_board_today === 1
    state.data_board_leave_meeting = info.data_board_leave_meeting === 1
    state.data_board_leave_tl = info.data_board_leave_tl === 1
    state.data_board_leave_other = info.data_board_leave_other === 1
    state.note_approve_list = info.note_approve_list === 1
    state.note_approve_list = info.note_approve_list
    state.meeting_approve_list = info.meeting_approve_list
    state.tl_approve_list = info.tl_approve_list
    state.scrivener_list = info.scrivener_list
  },
}

const actions = {
  loginByToken({ commit }, token) {
    return new Promise((resolve, reject) => {
      loginByToken(token)
        .then((res) => {
          const token = res.data.token
          commit('SET_TOKEN', token)
          setToken(token)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  loginByMobile({ commit }, mobile) {
    return new Promise((resolve, reject) => {
      loginByMobile(mobile)
        .then((res) => {
          const token = res.data.token
          commit('SET_TOKEN', token)
          setToken(token)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  login({ commit }, form) {
    return new Promise((resolve, reject) => {
      console.log(form)
      login(form.username, form.password)
        .then((res) => {
          const token = res.data.token
          commit('SET_TOKEN', token)
          setToken(token)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo()
        .then((res) => {
          commit('SET_USERINFO', res.data.user)

          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USERINFO', {
            id: 0,
            name: '', // 姓名
            mobile: '', // 手机
            dept: '', // 部门
            job: '', // 职务
            is_leader: false,
            is_note: false,
            approve_note: false,
            is_leave: false,
            approve_leave: false,
            data_board_note: false,
            data_board_today: false,
            data_board_leave_meeting: false,
            data_board_leave_tl: false,
            data_board_leave_other: false,
            note_approve_list: [],
            meeting_approve_list: [],
            tl_approve_list: [],
            scrivener_list: [], // 被代办人list
          })

          removeToken()
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
