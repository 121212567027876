import router from '@/router'
import getPageTitle from '@/utils/get-page-title'
import store from '@/store'

router.beforeEach(async (to, from, next) => {
  // 设置页面标题
  document.title = getPageTitle(to.meta.title)

  const token = store.getters.token

  console.log('token:', token)

  if (token) {
    // 判断个人信息是否存在, 不存在则调用接口去获取
    if (store.getters.id === 0) {
      await store.dispatch('user/getInfo').then(() => {
        next()
      })
    } else {
      next()
    }
  } else {
    // 未登录，判断路由是否需要登录
    if (to.meta.auth) {
      next('/oauth/not-login')
    } else {
      next()
    }
  }
})
