import http from '@/utils/http'

const ApiList = {
  list: '/v1/schedule/examine',
  detail: '/v1/schedule/examine/detail',
  update_status: '/v1/schedule/examine/update_status',
  todo_count: '/v1/schedule/examine/todo_count',
}

/**
 * 获取报名的比赛列表
 * @param {*} params
 * @returns
 */
export function getList(params) {
  return http.get(ApiList.list, { params })
}

/**
 *
 * @param {*} data
 * @returns
 */
export function updateStatus(data) {
  return http.post(ApiList.update_status, data)
}

/**
 * 获取详情数据
 * @returns
 */
export function getDetail(id) {
  return http.get(ApiList.detail, { params: { id } })
}

/**
 * 获取待审核数据数量
 */
export function getTodoCount() {
  return http.get(ApiList.todo_count)
}
