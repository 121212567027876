import { getToken, removeToken } from '../auth'
import router from '@/router'
import { Notify } from 'vant'

export default (http) => {
  // 请求拦截
  http.interceptors.request.use(
    (config) => {
      // 需要跨域打开此配置
      // config.withCredentials = true
      const token = getToken()
      if (token) {
        config.headers.Authorization = 'Bearer ' + token
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  http.interceptors.response.use(
    (response) => {
      const code = response.data.code
      // 判读响应数据
      // 状态码 200：正常响应，返回data
      if (200 === code) {
        return response.data
      }

      // 状态码 401：未登录，跳转到登录页面
      if (401 === code) {
        removeToken()
        router.push({ path: 'login' })
      }

      // 状态码 xxx：access_token 授权过期

      Notify({
        type: 'danger',
        message: response.data.message,
      })

      return Promise.reject(response.data)
    },
    (error) => {
      if (!error['response']) {
        return Promise.reject(error)
      }

      Notify({
        type: 'danger',
        message: '服务器出了点小问题，程序员小哥哥要被扣工资了~！',
      })

      return Promise.reject(error.response)
    }
  )
}
