import Vue from 'vue'
import VueRouter from 'vue-router'
import { BasicLayout } from '@/layout/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: BasicLayout,
    redirect: '/account',
    children: [
      {
        path: 'note',
        name: 'Note',
        component: () => import('@/views/note/index.vue'),
        meta: {
          auth: true,
          title: '日志',
        },
      },
      {
        path: 'note/detail/:id',
        name: 'NoteDetail',
        component: () => import('@/views/note/detail.vue'),
        meta: {
          auth: true,
          title: '日志详情',
        },
      },
      {
        path: 'note/create',
        name: 'NoteCreate',
        component: () => import('@/views/note/create.vue'),
        meta: {
          auth: true,
          title: '日志填报',
        },
      },
      {
        path: 'note/update/:id',
        name: 'NoteUpdae',
        component: () => import('@/views/note/update.vue'),
        meta: {
          auth: true,
          title: '日志编辑',
        },
      },
      {
        path: '/leave',
        name: 'Leave',
        component: () => import('@/views/leave/index.vue'),
        meta: {
          auth: true,
          title: '请假',
        },
      },
      {
        path: 'leave/detail/:id',
        name: 'LeaveDetail',
        component: () => import('@/views/leave/detail.vue'),
        meta: {
          auth: true,
          title: '请假详情',
        },
      },
      {
        path: 'leave/create',
        name: 'LeaveCreate',
        component: () => import('@/views/leave/create.vue'),
        meta: {
          auth: true,
          title: '新建假条',
        },
      },
      {
        path: 'leave/update/:id',
        name: 'LeaveUpdae',
        component: () => import('@/views/leave/update.vue'),
        meta: {
          auth: true,
          title: '编辑假条',
        },
      },
      {
        path: '/exam',
        name: 'Exam',
        component: () => import('@/views/exam/index.vue'),
        meta: {
          auth: true,
          title: '审核',
        },
      },
      {
        path: 'exam/detail/:id',
        name: 'ExamDetail',
        component: () => import('@/views/exam/detail.vue'),
        meta: {
          auth: true,
          title: '审核详情',
        },
      },
      {
        path: '/data-board',
        name: 'DataBoard',
        component: () => import('@/views/data-board/index.vue'),
        meta: {
          auth: true,
          title: '数据看板',
        },
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import('@/views/account/index.vue'),
        meta: {
          auth: true,
          title: '我的',
        },
      },
    ],
  },
  {
    path: '/oauth',
    name: 'OAuthLogin',
    component: () => import('@/views/oauth/index.vue'),
    meta: {
      auth: false,
      title: '登录',
    },
  },
  {
    path: '/oauth/not-login',
    name: 'OAuthNotLogin',
    component: () => import('@/views/oauth/not-login.vue'),
    meta: {
      auth: false,
      title: '请登录',
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/exception/404.vue'),
    meta: {
      auth: false,
      title: '登录',
    },
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    redirect: '/404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
