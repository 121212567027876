<template>
  <div class="wrapper">
    <router-view />

    <van-tabbar v-model="active" @change="handleChange" route>
      <van-tabbar-item replace to="/note" icon="notes-o" v-if="user.is_note"
        >日志</van-tabbar-item
      >
      <van-tabbar-item v-if="user.is_leave" replace to="/leave" icon="bag-o"
        >请假</van-tabbar-item
      >
      <van-tabbar-item
        v-if="isApprove"
        replace
        to="/exam"
        icon="passed"
        :badge="todoCount"
        >审核</van-tabbar-item
      >
      <van-tabbar-item
        replace
        to="/data-board"
        icon="browsing-history-o"
        v-if="isDataBoard"
        >数据看板</van-tabbar-item
      >
      <van-tabbar-item replace to="/account" icon="user-circle-o"
        >我</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
import { getTodoCount } from '@/api/exam'

export default {
  name: 'BasicLayout',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data() {
    return {
      active: 'home',
      todoCount: '',
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isApprove() {
      return this.user.approve_note || this.user.approve_leave
    },
    isDataBoard() {
      const user = this.user
      return (
        user.data_board_note ||
        user.data_board_today ||
        user.data_board_leave_meeting ||
        user.data_board_leave_tl ||
        user.data_board_leave_other
      )
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadTodoCount()
      // 定时获取todo数量
      setInterval(() => {
        this.loadTodoCount()
      }, 5 * 60 * 100)
    })
  },
  methods: {
    handleChange(active) {
      console.log(active)
    },
    loadTodoCount() {
      getTodoCount().then((res) => {
        console.log(res.data)
        const todoCount = res.data.todo_count
        this.todoCount = todoCount == 0 ? '' : todoCount
        // this.todoCount = todoCount
      })
    },
  },
}
</script>

<style lang="less" scoped>
.wrapper {
  padding-bottom: 70px;
  width: 100%;
}

@media screen and (min-width: 750px) {
  .wrapper {
    margin: 0 auto;
    width: 750px;
  }
}
</style>
