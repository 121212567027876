import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Vant from 'vant'

// 适配pc端浏览器
import '@vant/touch-emulator'

// 引入样式
import 'vue-easytable/libs/theme-default/index.css'
// 引入组件库
import '@/styles/index.less'

import '@/permission'

// import VueEasytable from 'vue-easytable'
import {
  VeTable,
  VePagination,
  VeIcon,
  VeLoading,
  VeLocale,
} from 'vue-easytable'

Vue.config.productionTip = false

Vue.use(VeTable)
Vue.use(VePagination)
Vue.use(VeIcon)
Vue.use(VeLoading)

Vue.prototype.$veLoading = VeLoading
Vue.prototype.$veLocale = VeLocale

// Vue.use(Vant)
// Vue.use(VueEasytable)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
