import http from '@/utils/http'

const ApiList = {
  login: '/v1/user/account/login',
  token_login: '/v1/user/account/token_login',
  logout: '/v1/user/account/logout',
  getInfo: '/v1/user/account/info',
  update: '/v1/user/account/update',
  updatePassword: '/v1/user/account/update_password',
}

export function loginByMobile(mobile) {
  return http({
    url: ApiList.login,
    method: 'post',
    data: {
      mobile,
    },
  })
}

export function loginByToken(token) {
  return http({
    url: ApiList.token_login,
    method: 'post',
    data: {
      token,
    },
  })
}

export function login(username, password) {
  return http({
    url: ApiList.login,
    method: 'post',
    data: {
      username,
      password,
    },
  })
}

export function logout() {
  return http({
    url: ApiList.logout,
    method: 'post',
  })
}

export function getUserInfo() {
  return http({
    url: ApiList.getInfo,
    method: 'get',
  })
}

export function updateUser(data) {
  return http({
    url: ApiList.update,
    method: 'post',
    data,
  })
}

export function updateUserPassword(data) {
  return http({
    url: ApiList.updatePassword,
    method: 'post',
    data,
  })
}
