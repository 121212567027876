import axios from 'axios'
import interceptors from './interceptors'

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 0,
})

interceptors(http)

export default http
